import React from 'react';

const GoogleAuthButton = () => {
  const handleGoogleAuth = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/google`;
  };

  return (
    <button onClick={handleGoogleAuth}>
      Google でログイン
    </button>
  );
};

export default GoogleAuthButton;
