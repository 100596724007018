import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getQuestionById, getQuestionsByCategoryId, saveUserAnswer } from '../../api/questions';
import './QuestionDetailPage.css';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';



const QuestionDetailPage = () => {
  const { id } = useParams();
  const [question, setQuestion] = useState(null);
  const [selectedChoice, setSelectedChoice] = useState(null);
  const [showExplanation, setShowExplanation] = useState(false);
  const navigate = useNavigate();
  const [categoryQuestions, setCategoryQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  // キャッシュバスター用のstate
  const [imageKey, setImageKey] = useState(Date.now());
  const [imageError, setImageError] = useState(false);
  // QuestionDetailPage.jsの先頭部分に追加
  const [imageLoading, setImageLoading] = useState(true);


  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        const fetchedQuestion = await getQuestionById(id);
        console.log('Fetched question:', fetchedQuestion); // デバッグログ
        setQuestion(fetchedQuestion);
        // 画像のキャッシュを強制的に更新
        setImageKey(Date.now());

        // 現在の問題のカテゴリIDを取得
        const categoryId = fetchedQuestion.Category.id;

        // 同じカテゴリの問題を取得
        const categoryQuestions = await getQuestionsByCategoryId(categoryId);
        setCategoryQuestions(categoryQuestions);

        // 現在の問題のインデックスを取得
        const index = categoryQuestions.findIndex((q) => q.id === Number(id));
        setCurrentQuestionIndex(index);

        // 選択肢、正解/不正解の表示、解説の表示をリセット
        setSelectedChoice(null);
        setShowExplanation(false);
      } catch (error) {
        console.error('Failed to fetch question:', error);
      }
    };

    // id が変更されるたびにデータを再取得
    if (id) {
      fetchQuestion();
    }
  }, [id]);

  if (!question || !question.QuestionChoices) {
    return <div className="loading">Loading...</div>;
  }

  const handleChoiceSelect = async (choiceId) => {
    setSelectedChoice(choiceId);
    setShowExplanation(true);

    // ユーザーの回答を保存
    try {
      await saveUserAnswer(question.id, choiceId);
    } catch (error) {
      console.error('Failed to save user answer:', error);
    }
  };

  const isCorrectChoice = selectedChoice && question.QuestionChoices.find(
    (choice) => choice.id === selectedChoice
  )?.is_correct;

  const handlePrevQuestion = () => {
    // 前の問題に遷移する処理を追加
    if (categoryQuestions.length === 0) return;
    const prevIndex = currentQuestionIndex - 1;
    if (prevIndex >= 0) {
      const prevQuestionId = categoryQuestions[prevIndex].id;
      navigate(`/questions/${prevQuestionId}`);
    }
  };

  const handleNextQuestion = () => {
    if (categoryQuestions.length === 0) return;
    const nextIndex = currentQuestionIndex + 1;
    if (nextIndex < categoryQuestions.length) {
      const nextQuestionId = categoryQuestions[nextIndex].id;
      navigate(`/questions/${nextQuestionId}`);
    }
  };
  const handleGoBack = () => {
    navigate('/questions', { state: { fromQuestionDetail: true } });
  };

  // 以前の未使用のコードを削除し、正しい位置にハンドラー関数を定義
  const handleImageLoad = () => {
    setImageLoading(false);
    setImageError(false);
    console.log('Image loaded successfully:', question?.image_url);
  };

  const handleImageError = (e) => {
    setImageLoading(false);
    setImageError(true);
    console.error('Image load error:', question?.image_url);
    e.target.style.display = 'none';
  };


  return (
    <div className="question-detail-page">
      <div className="question-detail-container">
        <h1>{question.title}</h1>
        <p>{question.statement}</p>

        {/* 画像の表示を追加 */}
        {question.image_url && question.image_url !== 'null' && !imageError && (
          <div className="question-image">
            <img
              key={imageKey}
              src={`${question.image_url}?v=${Date.now()}`} // タイムスタンプでキャッシュ制御
              alt="Question illustration"
              className="question-image__img"
              onError={(e) => {
                console.error('Image load error for URL:', question.image_url);
                setImageError(true);
                e.target.style.display = 'none';
              }}
              onLoad={() => {
                console.log('Image loaded successfully:', question.image_url);
                setImageError(false);
              }}
            />
          </div>
        )}

        <div className="choices">
          {question.QuestionChoices.map((choice, index) => (
            <button
              key={choice.id}
              className={`choice ${selectedChoice === choice.id ? 'selected' : ''}`}
              onClick={() => handleChoiceSelect(choice.id)}
              disabled={selectedChoice !== null}
            >
              {`${String.fromCharCode(65 + index)}. ${choice.choice_text}`}
            </button>
          ))}
        </div>
        {selectedChoice && (
          <div className={`result ${isCorrectChoice ? 'correct' : 'incorrect'}`}>
            {isCorrectChoice ? '正解です！' : '不正解です。'}
          </div>
        )}
        {showExplanation && (
          <div className="explanation">
            <h2>解説</h2>
            <p>{question.explanation}</p>
          </div>
        )}
        <div className="navigation-container">
          <button
            className="nav-button prev"
            onClick={handlePrevQuestion}
            disabled={currentQuestionIndex === 0}
            aria-label="Previous question"
          >
            <FaArrowLeft />
            <span className="button-text">前</span>
          </button>
          <button onClick={handleGoBack} className="back-button">戻る</button>
          <button
            className="nav-button next"
            onClick={handleNextQuestion}
            disabled={currentQuestionIndex === categoryQuestions.length - 1}
            aria-label="Next question"
          >
            <span className="button-text">次</span>
            <FaArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuestionDetailPage;
