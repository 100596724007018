import React, { createContext, useState, useEffect, useContext } from 'react';
import { setAuthToken } from '../api';
import api from '../api';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        setAuthToken(token);
        try {
          const response = await api.get('/users/profile');
          setIsAuthenticated(true);
          setRole(response.data.role);
        } catch (error) {
          console.error('Error checking authentication:', error);
          localStorage.removeItem('token');
          setAuthToken(null);
        }
      }
      setLoading(false);
    };

    checkAuth();
  }, []);

  const login = (token, userRole) => {
    localStorage.setItem('token', token);
    localStorage.setItem('userRole', userRole); // ロールも保存
    setAuthToken(token);
    setIsAuthenticated(true);
    setRole(userRole);
    console.log('Login - Role:', userRole);
  };

  const logout = () => {
    localStorage.removeItem('token');
    setAuthToken(null);
    setIsAuthenticated(false);
    setRole(null);
  };

  const updateUserRole = (newRole) => {
    setRole(newRole);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, role, login, logout, loading, updateUserRole }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
