import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { getQuestions, deleteQuestion } from '../../api/questions';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './QuestionsPage.css';

const QuestionPage = () => {
  const [questions, setQuestions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [difficulties, setDifficulties] = useState([]);
  const [searchParams, setSearchParams] = useState(() => {
    const savedParams = localStorage.getItem('searchParams');
    return savedParams ? JSON.parse(savedParams) : {
      category: '',
      subcategory: '',
      difficulty: '',
      status: '' // 新しく追加
    };
  });
  const { isAuthenticated, role } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async (params = searchParams) => {
    try {
      const data = await getQuestions(role, params);
      console.log('Fetched data:', data);
      setQuestions(data.questions || []);
      setCategories(data.categories || []);
      setSubcategories(data.subcategories || []);
      setDifficulties(data.difficulties || []);
    } catch (error) {
      console.error('Error fetching data:', error);
      setQuestions([]);
      setCategories([]);
      setSubcategories([]);
      setDifficulties([]);
    }
  };


  useEffect(() => {
    fetchData();
  }, [role]); // role が変更されたときだけ再取得4


  useEffect(() => {
    // ブラウザの戻るボタンで戻ってきた場合の処理
    const handlePopState = () => {
      const savedParams = localStorage.getItem('searchParams');
      if (savedParams) {
        const parsedParams = JSON.parse(savedParams);
        setSearchParams(parsedParams);
        fetchData(parsedParams);
      }
    };

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, []);

  const handleSearchChange = (e) => {
    const newSearchParams = { ...searchParams, [e.target.name]: e.target.value };
    setSearchParams(newSearchParams);
    localStorage.setItem('searchParams', JSON.stringify(newSearchParams));
  };

  const handleSearch = async () => {
    try {
      console.log('Search params:', searchParams);  // デバッグ用
      const data = await getQuestions(role, searchParams);
      console.log('Search results:', data);  // デバッグ用
      setQuestions(data.questions || []);
      setCategories(data.categories || []);
      setSubcategories(data.subcategories || []);
      setDifficulties(data.difficulties || []);
    } catch (error) {
      console.error('Error searching questions:', error);
      setQuestions([]);
    }
  };

  const handleEditQuestion = (questionId) => {
    navigate(`/questions/${questionId}/edit`);
  };

  const handleDeleteQuestion = async (questionId) => {
    try {
      await deleteQuestion(questionId);
      await fetchData();
    } catch (error) {
      console.error('Error deleting question:', error);
    }
  };

  return (
    <div className="question-page">
      <h1 className="question-page-title">問題一覧</h1>

      <div className="search-form">
        <select name="category" onChange={handleSearchChange} value={searchParams.category}>
          <option value="">全てのカテゴリ</option>
          {categories.map(category => (
            <option key={category.id} value={category.id}>{category.name}</option>
          ))}
        </select>
        <select name="subcategory" onChange={handleSearchChange} value={searchParams.subcategory}>
          <option value="">全てのサブカテゴリ</option>
          {subcategories.map(subcategory => (
            <option key={subcategory.id} value={subcategory.id}>{subcategory.name}</option>
          ))}
        </select>
        <select name="difficulty" onChange={handleSearchChange} value={searchParams.difficulty}>
          <option value="">全ての難易度</option>
          {difficulties.map(difficulty => (
            <option key={difficulty} value={difficulty}>{difficulty}</option>
          ))}
        </select>
        <select name="status" onChange={handleSearchChange} value={searchParams.status}>
          <option value="">全ての状態</option>
          <option value="correct">正解</option>
          <option value="incorrect">不正解</option>
          <option value="unattempted">未着手</option>
        </select>
        <button onClick={handleSearch}>検索</button>
      </div>

      <div className="question-list">
        {questions.map((question) => (
          <div key={question.id} className="question-item">
            <h2 className="question-title">{question.title}</h2>
            <div className="question-details">
              <p className="question-category">カテゴリ: {question.category}</p>
              <p className="question-subcategory">サブカテゴリ: {question.subcategory}</p>
              <p className="question-difficulty">難易度: {question.difficulty}</p>
              {isAuthenticated && (
                <p className={`question-status ${question.status === '正解' ? 'status-correct' :
                  question.status === '不正解' ? 'status-incorrect' :
                    'status-unattempted'
                  }`}>
                  {question.status || '未着手'}
                </p>
              )}
            </div>
            <Link to={`/questions/${question.id}`} className="question-start-btn">
              スタート
            </Link>
            {isAuthenticated && role === 'admin' && (
              <div className="question-actions">
                <button className="question-edit-btn" onClick={() => handleEditQuestion(question.id)}>
                  編集
                </button>
                <button className="question-delete-btn" onClick={() => handleDeleteQuestion(question.id)}>
                  削除
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuestionPage;
